'use client'
// Components
import { Button } from '@/_components/ui/button'

export default function NotFound() {
  return (
    <div className="flex flex-col text-center items-center justify-center h-screen">
      <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
        🤔 Not found
      </h1>
      <p className="mt-6 text-base leading-7">
        Sorry, we could not find the requested resource.
      </p>
      <div className="mt-10 flex items-center justify-center space-x-6">
        <Button onClick={() => window.history.back()}>Go Back</Button>
        <Button asChild variant="link">
          <a target="_blank" href="https://support.stowlog.com">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </Button>
      </div>
    </div>
  )
}

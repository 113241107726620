'use client'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import {
  Tooltip as ShadcnTooltip,
  TooltipContent,
  TooltipTrigger
} from '@/_components/ui/tooltip'

interface Props extends React.PropsWithChildren {
  content: string | React.ReactNode
  contentProps?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
}

export const STLTooltip = ({ children, content, contentProps }: Props) => {
  if (content == null || content === '') return <>{children}</>

  return (
    <ShadcnTooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent
        sideOffset={5}
        className="bg-primary-dark/80 text-white"
        side="top"
        align="center"
        {...contentProps}
      >
        {content}
      </TooltipContent>
    </ShadcnTooltip>
  )
}

import { InfiniteData } from '@tanstack/react-query'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { ZodError } from 'zod'
import { generateErrorMessage } from 'zod-error'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const PASSWORD_REGEX = /^[a-zA-Z0-9\S]{8,}$/

export const NUMBERS_REGEX = /[0-9]/
export const UPPER_CASE_REGEX = /[A-Z]/
export const LOWER_CASE_REGEX = /[a-z]/
export const SYMBOL_REGEX = /[-#!$@£%^&*()_+|~=`{}[\]:";'<>?,./]/
export const NO_SPACE_REGEX = /^[^\s]*$/

export const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
)

export const getErrorMessageDescription = (error: any) => {
  if (error instanceof ZodError) {
    const zodMessage = generateErrorMessage(error.issues)
    return zodMessage
  }
  return ''
}

export const getResultsFromInfiniteQuery = (
  queryData: InfiniteData<any> | undefined
) => {
  let results
  if (
    !(
      Array.isArray(queryData?.pages) &&
      queryData?.pages.length === 1 &&
      Array.isArray(queryData?.pages[0]) &&
      queryData?.pages[0]?.length === 0
    )
  ) {
    results = queryData?.pages.flatMap((item) => item.data ?? item.results)
  }
  return results
}


export const stringToRegex = (regexString: string) => {
  const regexPattern = regexString.slice(1, regexString.lastIndexOf('/'))
  const regexFlags = regexString.slice(regexString.lastIndexOf('/') + 1)

  const escapedPattern = regexPattern.replace(/\\\\/g, '\\\\')

  return new RegExp(escapedPattern, regexFlags)
}